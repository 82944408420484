<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="아니오">아니오</b-form-radio>
              <b-form-radio value="24시간 교대근무">24시간 교대근무</b-form-radio>
              <b-form-radio value="다른형태 근무">다른형태 근무</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q20',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '교대근무',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '교대근무',
        answer: [
          {
            data: '',
          },
        ],
      };
    }
  },
};
</script>